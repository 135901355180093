<template>
  <div class="nvr-comp">
    <el-button
      type="primary"
      @click="addNVR"
    >添加NVR</el-button>
    <el-table
      :data="tableData"
      style="width: 100%"
      :loading="loading"
    >
      <el-table-column
        prop="mediaServerId"
        label="SIP用户认证ID"
        min-width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="pwd"
        label="SIP用户认证密码"
        min-width="180"
      ></el-table-column>
      <el-table-column
        prop="brand"
        label="NVR厂商"
        min-width="180"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="NVR状态"
        min-width="180"
      >
        <template slot-scope="scope">
          <status :data="scope.row.status"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="channelCnt"
        label="绑定摄像头数量"
        min-width="180"
      ></el-table-column>
      <el-table-column
        label="操作"
        min-width="180"
      >
        <template slot-scope="scope">
          <el-button @click="restSecond(scope.row)" type="text" size="small">摄像头管理</el-button>
          <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <dialog-add-box
      :dialogVisible="dialogVisible"
      @confirm="handleConfirm"
      @close="handleClose"
      has-solution
    />
  </div>
</template>

<script>
import DialogAddBox from '@/views/nvrManage/solution/DialogAddBox/index.vue'
import { getNvrList, removeNvr } from '@/api/nvr'
import { solution } from '@/api/product'
import Status from 'src/components/Status'

export default {
  components: { DialogAddBox, Status },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      tableData: []
    }
  },
  mounted() {
    this.getNvrList()
  },
  methods: {
    addNVR() {
      this.dialogVisible = true
    },
    async getNvrList() {
      try {
        this.loading = true
        const res = await getNvrList({productId: this.$route.query.productId})
        if (res.status === 0) {
          this.tableData = res.data
        }
      } finally {
        this.loading = false
      }
    },
    handleConfirm() {
      this.dialogVisible = false
      this.getNvrList()
    },
    handleClose() {
      this.dialogVisible = false
    },
    async handleDelete(row) {
      this.$msgbox({
        message: `NVR (${row.mediaServerId}) 已绑定 ${row.channelCnt} 个摄像头, 确定删除？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const { status } = await removeNvr({ productId: this.$route.query.productId, nvrId: row.nvrId })
            if (status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              await this.getNvrList()
            }
            instance.confirmButtonLoading = false
          }
          done()
        },
      }).catch(() => {
      })
    },
    restSecond(row) {
      this.$router.push({
        name: 'nvr-manage-cameraManage',
        query: {
          shopName: this.$route.query.shopName,
          cname: this.$route.query.cname,
          status: this.$route.query.status,
          productId: this.$route.query.productId,
          nvrId: row.nvrId,
        }
      })
    }
  }
}
</script>

<style>
.nvr-comp {
  padding: 20px;
}
</style>

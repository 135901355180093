var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"item mr40"},[_vm._m(0),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.cname || '--'))])]),_c('div',{staticClass:"item mr40"},[_vm._m(1),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.shopName || '--'))])]),_c('div',{staticClass:"item mr40"},[_vm._m(2),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.productId))])]),_c('div',{staticClass:"item mr40"},[_vm._m(3),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.shopId || '--'))])]),_c('div',{staticClass:"item mr40"},[_vm._m(4),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.formatLabel(_vm.runningStatusList, parseInt(_vm.status)) || '--'))])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]),_c('div',{staticClass:"mt20"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-tab-pane',{attrs:{"lazy":"","label":"NVR","name":"0"}},[_c('nvr-comp')],1),_c('el-tab-pane',{attrs:{"lazy":"","label":"摄像头","name":"1"}},[_c('camera-comp')],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"label"},[_c('strong',[_vm._v("客户名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"label"},[_c('strong',[_vm._v("门店名称：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"label"},[_c('strong',[_vm._v("SolutionID：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"label"},[_c('strong',[_vm._v("shopID：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"label"},[_c('strong',[_vm._v("状态：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item mr40"},[_c('span',{staticClass:"label"},[_c('strong',[_vm._v("SIP服务器IP：")])]),_c('span',{staticClass:"value"},[_vm._v("49.232.54.205")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item mr40"},[_c('span',{staticClass:"label"},[_c('strong',[_vm._v("SIP服务器端口：")])]),_c('span',{staticClass:"value"},[_vm._v("8115")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item mr40"},[_c('span',{staticClass:"label"},[_c('strong',[_vm._v("服务器ID：")])]),_c('span',{staticClass:"value"},[_vm._v("41010500002000000001")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item mr40"},[_c('span',{staticClass:"label"},[_c('strong',[_vm._v("服务器域：")])]),_c('span',{staticClass:"value"},[_vm._v("1101050048")])])
}]

export { render, staticRenderFns }
<template>
  <div class="camera-comp">
    <el-table
      :data="tableData"
      style="width: 100%"
      :loading="loading"
    >
      <el-table-column
        label="设备名称"
        prop="channelName"
        width="180"
        show-overflow-tooltip
      />
      <el-table-column
        label="通道ID"
        prop="channelId"
        min-width="180"
        show-overflow-tooltip
      />
      <el-table-column
        label="关联的NVR"
        prop="mediaServerId"
        min-width="180"
        show-overflow-tooltip
      />
      <el-table-column
        label="cameraid"
        prop="cameraId"
        min-width="180"
        show-overflow-tooltip
      />
      <el-table-column
        prop="status"
        min-width="180"
        label="状态"
      >
        <template slot-scope="scope">
          <status :data="scope.row.status"/>
        </template>
      </el-table-column>
      <el-table-column
        label="绑定时间"
        prop="bindTime"
        min-width="180"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        prop=""
        min-width="180"
      >
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handlePlay(scope.row)">播放</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getNvrCameraList } from '@/api/nvr'
import Status from 'components/Status'

export default {
  components: { Status },
  data() {
    return {
      tableData: [],
      productId: this.$route.query.productId,
      loading: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        const res = await getNvrCameraList({
          productId: this.productId,
          bindStatus: 1
        })
        if (res.status  === 0) {
          this.tableData = res.data
        }
      } finally {
        this.loading = false
      }
    },
    handlePlay(row) {
      this.$router.push({
        name: 'nvr-manage-cameraManage-live',
        query: {
          cameraId: row.cameraId,
          nvrId: row.nvrId,
          channelName: row.channelName || '',
          channelId: row.channelId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.camera-comp {
  padding: 20px;
}
</style>

<template>
  <div class="app-container">
    <div class="info">
      <div class="item mr40">
        <span class="label"><strong>客户名称：</strong></span>
        <span class="value">{{ cname || '--' }}</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>门店名称：</strong></span>
        <span class="value">{{ shopName || '--' }}</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>SolutionID：</strong></span>
        <span class="value">{{ productId }}</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>shopID：</strong></span>
        <span class="value">{{ shopId || '--' }}</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>状态：</strong></span>
        <span class="value">{{ formatLabel(runningStatusList, parseInt(status)) || '--' }}</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>SIP服务器IP：</strong></span>
        <span class="value">49.232.54.205</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>SIP服务器端口：</strong></span>
        <span class="value">8115</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>服务器ID：</strong></span>
        <span class="value">41010500002000000001</span>
      </div>
      <div class="item mr40">
        <span class="label"><strong>服务器域：</strong></span>
        <span class="value">1101050048</span>
      </div>
    </div>
    <div class="mt20">
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane lazy label="NVR" name="0">
          <nvr-comp />
        </el-tab-pane>
        <el-tab-pane lazy label="摄像头" name="1">
          <camera-comp />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { formatLabel } from 'src/utils/common'
import { runningStatusList } from 'src/utils/dict'
import NvrComp from '@/views/nvrManage/device/NvrComp.vue'
import CameraComp from '@/views/nvrManage/device/CameraComp.vue'

export default {
  components: {
    NvrComp,
    CameraComp,
  },
  data() {
    const { cname, shopName, productId, shopId, status, domain, port } = this.$route.query

    return {
      activeTab: this.$route.query.type || '0',
      cname,
      shopName,
      productId,
      shopId,
      status,
      domain,
      port,
      runningStatusList,
    }
  },
  computed: {
    showNvr() {
      return this.$route.name === 'retail-traffic-device-index'
    }
  },
  methods: {
    handleClick(tab) {
      this.activeTab = tab.name
      this.$router.push({ query: { ...this.$route.query, type: tab.name } })
    },
    formatLabel,
  },
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  word-break: break-all;
  flex-wrap: wrap;
  gap: 12px;
}
</style>
